import { addPropertyControls, ControlType } from "framer"
import { useEffect, useState } from "react"
import {
    FormIcon,
    iconPropertyControls,
    useFormStore,
} from "https://framer.com/m/SharedFormCode-HQOZ.js@TqcitBC3zIme9QoXbJuf"
import { motion } from "framer-motion"

export default function SubmitButton(props) {
    const {
        clickAction,
        submitUrl,
        redirectOnSuccess,
        scrollOptions,
        icon,
        border,
        success,
        failure,
    } = props
    const borderRadius = props.radiusIsMixed
        ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
        : `${props.radius}px`

    const [formState, setFormState] = useFormStore()

    // Reset/clear form
    function resetFormState() {
        setFormState((prev) => {
            const newFormState = {}
            for (const fieldName in prev) {
                newFormState[fieldName] = {
                    ...prev[fieldName],
                    value: prev[fieldName].defaultValue,
                }
            }
            return newFormState
        })
    }

    useEffect(() => {
        console.log("Form State Updated", formState)
    }, [formState])

    useEffect(() => {
        setFormState({})
    }, [])

    async function onSubmitClick() {
        const invalidFields = []
        const formData = {}

        // Find invalid fields
        for (const fieldName in formState) {
            const field = formState[fieldName]

            formData[fieldName] = field.value
            if (
                field.required &&
                field.visible &&
                !field.isValid(field.value)
            ) {
                invalidFields.push(fieldName)
            }
        }

        if (invalidFields.length == 0) {
            if (clickAction == "submitForm") {
                try {
                    const response = await fetch(submitUrl, {
                        method: "POST",
                        mode: submitUrl.includes("submit-form.com") // Fix issues with Formspark
                            ? "cors"
                            : "no-cors",
                        body: JSON.stringify(formData),
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })

                    if (response.ok || response.type == "opaque") {
                        if (
                            redirectOnSuccess &&
                            !window.location.origin.endsWith("framercanvas.com")
                        ) {
                            window.location.href = redirectOnSuccess // Redirect to new URL
                        } else {
                            resetFormState()
                            success?.()
                        }
                    } else {
                        failure?.()
                    }
                } catch (error) {
                    console.error("Error submitting form: ", error)
                    failure?.()
                }
            } else {
                // Validate form without submitting to URL
                success?.()
            }
        } else {
            console.log(
                "Not all required form fields are filled:",
                invalidFields
            )

            if (scrollOptions) {
                // Scroll to first invalid field
                let closestRef = null
                let closestDistance = Number.POSITIVE_INFINITY

                for (const fieldName in formState) {
                    const ref = formState[fieldName].ref

                    if (invalidFields.includes(fieldName) && ref.current) {
                        const rect = ref.current.getBoundingClientRect()
                        const distance = rect.top + window.pageYOffset
                        if (distance >= 0 && distance < closestDistance) {
                            closestRef = ref
                            closestDistance = distance
                        }
                    }
                }

                if (closestRef) {
                    window.scrollTo({
                        behavior: "smooth",
                        top:
                            closestRef.current.getBoundingClientRect().top -
                            document.body.getBoundingClientRect().top +
                            scrollOptions.offset,
                    })
                }
            }

            failure?.()
        }
    }

    return (
        <button
            type="submit"
            onClick={onSubmitClick}
            style={{
                position: "relative",
                display: "flex",
                flexDirection:
                    icon?.position == "right" ? "row-reverse" : "row",
                gap: icon?.gap,
                alignItems: "center",
                justifyContent: props.font?.textAlign || "center",
                color: props.fontColor,
                border: "none",
                outline: "none",
                cursor: "pointer",
                userSelect: props.textSelect ? "auto" : "none",
                borderRadius: borderRadius,
                padding: props.paddingIsMixed
                    ? `${props.paddingTop}px ${props.paddingRight}px ${props.paddingBottom}px ${props.paddingLeft}px`
                    : `${props.padding}px`,
                backgroundColor: props.backgroundColor,
                accentColor: props.accentColor,
                ...props.font,
                ...props.style,
            }}
        >
            <FormIcon icon={icon} style={{}} />
            {props.text}
            {border && (
                <div
                    style={{
                        position: "absolute",
                        inset: 0,
                        borderWidth: border.widthIsMixed
                            ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                            : `${border.width}px`,
                        borderStyle: border.style,
                        borderColor: border.color,
                        borderRadius: borderRadius,
                        pointerEvents: "none",
                    }}
                />
            )}
        </button>
    )
}

SubmitButton.displayName = "Submit Button"

addPropertyControls(SubmitButton, {
    formHolder: {
        type: ControlType.ScrollSectionRef,
    },
    clickAction: {
        type: ControlType.Enum,
        defaultValue: "submitForm",
        options: ["submitForm", "validateForm"],
        optionTitles: ["Submit Form", "Validate Form"],
    },
    submitUrl: {
        title: "Submit URL",
        type: ControlType.String,
        defaultValue: "",
        hidden: (props) => props.clickAction != "submitForm",
    },
    redirectOnSuccess: {
        type: ControlType.Link,
        hidden: (props) => props.clickAction != "submitForm",
    },
    backgroundColor: {
        type: ControlType.Color,
        defaultValue: "#0075FF",
        title: "Background",
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1,
        },
    },
    text: {
        type: ControlType.String,
        defaultValue: "Submit",
    },
    scrollToInvalidField: {
        type: ControlType.Object,
        defaultValue: { offset: -24 },
        optional: true,
        controls: {
            offset: {
                type: ControlType.Number,
                defaultValue: -24,
                step: 1,
            },
        },
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#222",
            },
            width: {
                type: ControlType.FusedNumber,
                defaultValue: 1,
                toggleKey: "widthIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "widthTop",
                    "widthRight",
                    "widthBottom",
                    "widthLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 1,
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    },
    icon: iconPropertyControls,
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 16,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 8,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    textSelect: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    success: {
        type: ControlType.EventHandler,
    },
    failure: {
        type: ControlType.EventHandler,
    },
})
